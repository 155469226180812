<template>
    <div class="editor">
        <slot name="default"></slot>
    </div>
</template>

<script setup></script>

<style>
.editor a {
    @apply text-primary hover:underline;
}

.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5,
.editor h6 {
    @apply font-semibold mt-8 mb-4 first:mt-0;
}

.editor h1 {
    @apply text-4xl md:text-6xl;
}

.editor h2 {
    @apply text-3xl md:text-5xl;
}

.editor h3 {
    @apply text-2xl md:text-4xl;
}

.editor h4 {
    @apply text-xl md:text-3xl;
}

.editor h5 {
    @apply text-lg md:text-2xl;
}

.editor h6 {
    @apply text-base md:text-xl;
}

.editor ol {
    @apply list-decimal pl-4 grid gap-2 mb-4;
}

.editor ul {
    @apply list-disc pl-4 grid gap-2 marker:text-primary mb-4;
}

.editor p {
    @apply empty:hidden break-words;
}

.editor strong {
    @apply font-bold;
}

.editor hr {
    @apply my-6;
}
</style>
